'use strict';

(function (factory) {
    factory(jQuery);
}(function ($) {

    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: DE (German, Deutsch)
     */
    $.extend($.validator.messages, {
        'invalid': 'Por favor, introduce datos válidos',
        'legalDate': 'Debes tener al menos 18 años para realizar pedidos en la Tienda online de Jack Wolfskin.',
        'maxlength': $.validator.format('Por favor, introduce como máximo {0} caracteres.'),
        'minlength': $.validator.format('Por favor, introduce como mínimo {0} caracteres.'),
        'rangelength': $.validator.format('Por favor, introduce como mínimo {0} y como máximo {1} caracteres.'),
        'email': 'Por favor, introduce una dirección de correo electrónico válida.',
        'url': 'Por favor, introduce una URL válida.',
        'date': 'Por favor, introduce una fecha válida.',
        'number': 'Por favor, introduce un número.',
        'digits': 'Por favor, introduce solo dígitos.',
        'equalTo': 'Por favor, repite el mismo valor.',
        'range': $.validator.format('Por favor, introduce un valor entre {0} y {1}.'),
        'max': $.validator.format('Por favor, introduce un valor menor o igual a {0}.'),
        'min': $.validator.format('Por favor, introduce un valor igual o superior a {0}'),
        'creditcard': 'Por favor, introduce un número de tarjeta de crédito válido.',
        'invalidZip': 'Por favor, introduce un código postal válido',
        'couponCodeMistakenForGiftCard': 'Por favor, introduce el código de vale o promoción. Si dispones de una tarjeta regalo, puedes canjearlo en el cierre de la compra',
        'invalidGiftCard': 'Por favor, introduce el número de la tarjeta regalo Si dispones de un código de vale o promoción, puedes canjearlo en la cesta.',
        'streetMissingHouseNumber': 'No dio el número de la casa, ¿es correcto?',
        'invalidPassword': 'La contraseña debe contener al menos un carácter especial, una letra mayúscula y un número, y tener una longitud mínima de 8 caracteres.',
        'passwordsDontMatch': 'Las contraseñas no coinciden.',
        'selectReturnReason': 'Por favor, selecciona un motivo de devolución para continuar'
    });
    return $;
}));
