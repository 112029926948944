(function( factory ) {
	if ( typeof define === "function" && define.amd ) {
		define( ["jquery", "../jquery.validate"], factory );
	} else if (typeof module === "object" && module.exports) {
		module.exports = factory( require( "jquery" ) );
	} else {
		factory( jQuery );
	}
}(function( $ ) {

/*
 * Translated default messages for the jQuery validation plugin.
 * Locale: DE (German, Deutsch)
 */
$.extend( $.validator.messages, {
	required: "Este campo es obligatorio",
	maxlength: $.validator.format( "Por favor, introduce como máximo {0} caracteres." ),
	minlength: $.validator.format( "Por favor, introduce como mínimo {0} caracteres." ),
	rangelength: $.validator.format( "Por favor, introduce como mínimo {0} y como máximo {1} caracteres.'" ),
	email: "Por favor, introduce una dirección de correo electrónico válida.",
	url: "Por favor, introduce una URL válida.",
	date: "Por favor, introduce una fecha válida.",
	number: "Por favor, introduce un número.",
	digits: "Por favor, introduce solo números",
	equalTo: "Por favor, repite el mismo valor",
	range: $.validator.format( "Por favor, introduce un valor entre {0} y {1}." ),
	max: $.validator.format( "Por favor, introduce un valor menor o igual a {0}." ),
	min: $.validator.format( "Por favor, introduce un valor igual o superior a {0}" ),
	creditcard: "Por favor, introduce un número de tarjeta de crédito válido."
} );
return $;
}));
